<template>
  <!-- 列表样式 -->
  <div
    class="chat-robot-box"
    style="display: block;"
  >
    <!-- <div class="message-header"
        :class="{'border-bottom': !isChild}">
      <em class="icon-dot"></em>近期热点咨询
    </div> -->
    <ul
      class="list"
      :class="listStyle"
      style="padding-top: .12rem;"
      ref="listContainer"
    >
      <li
        v-for="(item) in list"
        :key="item.id"
        @click="selectItem(item)"
      >
        {{item.name}}
      </li>
    </ul>
    <!-- 查看更多按钮 -->
    <div
      class="view-more"
      :class="{ open: allDisplayed }"
      v-if="hasMore"
      @click="clickDisplayed"
    >{{allDisplayed ? '收起' : '其他问题'}}</div>
  </div>
</template>

<script>
import { buildData } from '@/components/Bubbles/interface'
import { mapState } from 'vuex'
// import _ from 'lodash'
export default {
  name: 'BubbleList',
  props: {
    message: Object,
    isChild: Boolean
  },
  data () {
    return {
      defaultCount: 6,
      allDisplayed: false
    }
  },
  computed: {
    ...mapState(['level']),
    hasMore () {
      return this.message.content.length > this.defaultCount
    },
    list () {
      // // 判断设置字符超长展示样式 0407 hmm
      // this.message.content.forEach(item => {
      //   console.log(item.name.length)
      //   if (item.name.length > 8) {
      //     item.class = 'width100'
      //   }
      // })
      if (this.allDisplayed) {
        return this.message.content
      } else {
        return this.message.content.slice(0, this.defaultCount)
      }
    },
    listStyle () {
      // 遍历所有问题的长度，如果有问题的长度过长就2行显示
      let length = 0
      const content = this.message.content
      content.forEach(item => {
        length = Math.max(item.name.length, length)
      })

      return length > 12 ? 'layout-one' : 'layout-two'
    }
  },
  methods: {
    clickDisplayed () {
      this.allDisplayed = !this.allDisplayed
      // this.$store.commit('updateFreshNeed', true)
    },
    selectItem (item) {
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: item.name,
        dataType: 'subQuestion-selected'
      })
      console.log(this.$store.state.navigation)
      console.log(this.$store.state.exam)
      if (this.$store.state.exam.level >= 3) {
        return
      }

      if (this.$store.state.navigation && this.$store.state.examId) {
        item.level = 3
        item.message = this.message
        this.$store.commit('updateExam', item)
      }
    }
  },
  mounted () {
    const it = {
      type: 18,
      content: '咨询其他问题您可以直接在输入框输入'
    }
    this.$store.commit('appendBubble', buildData(it))
    if (!this.$store.state.exam) {
      const item = {
        name: this.$store.state.examName,
        level: 2
      }
      this.$store.commit('updateExam', item)
      this.$store.commit('updateExamName', item.name)
      this.$store.commit('updateExamId', item.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.list{
  @include clear();
  &.layout-one li {
    box-sizing: border-box;
    width: 100%;
    padding-right: 0.12rem;
    float: none;
  }
  &.layout-two li {
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-right: 0.05rem;
  }
}
@media only screen and (device-width: 700px ) {
  .list{
    &.layout-one li {
      padding-right: 0.06rem;
    }
  }
}
</style>
